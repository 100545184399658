@use '../../Util/Styles/Mixins.scss';

.header {
  position: fixed;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 200px;
  column-gap: 20px;
  top: 20px;
  width: 1220px;
  height: 80px;
  padding: 0 20px;
  z-index: 8;
  
  
  border-radius: 24px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

  @media (max-width: 1220px) {
    width: 97vw;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 200px;
    background-image: url('../../Util/Images/MainPage/URBANIST (1).svg');
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    a {
      display: flex;
      align-self: center;
      justify-content: center;
      height: 100%;
      text-decoration: none;
      @include Mixins.default-font-picker(16px,400,#270D56);
    }

    .main-page--link {
      width: 40px;
      height: 100%;
      background-image: url('../../Util/Images/Header/U.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transition: 300ms;

      @media (max-width: 1024px) {
        width: 32px;
        height: 100%;
      }

      cursor: pointer;

      &:hover {
        transform: scale(95%);
      }
    }

    .link {
      display: flex;
      align-items: center;
      height: 100%;
      @include Mixins.default-font-picker(16px,400,#270D56);
      cursor: pointer;
      transition: 300ms;
      position: relative;

      @media (max-width: 1024px) {
        display: none;
      }

      &::after {
        content: '';
        width: 40px;
        height: 6px;
        background-color: rgba(0,0,0, 0.0);
        position: absolute;
        left: 50%;
        bottom: -3px;
        transform: translateX(-50%);
        border-radius: 6px;
        transition: 300ms;
      }

      &:hover {
        &::after {
          background-color: #7C37FA;
        }
      }
    }

    .selected {
      &::after {
        background-color: #7C37FA;
      }
    }

    .menu-button {
      display: none;
      width: 24px;
      height: 24px;
      background-size: cover;
      background-image: url('../../Util/Images/Header/category.svg');

      cursor: pointer;

      @media (max-width: 1024px) {
        display: block;
      }
    }
  }

  .downloads-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    text-align: center;

    background: linear-gradient(90deg, #A882FF 0%, #6A1FFA 100%);
    border-radius: 18px;

    @include Mixins.default-font-picker(14px,600,white);

    cursor: pointer;
    transition: 300ms;

    &:hover {
      transform: scale(95%);
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.header__menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateZ(4);
  z-index: 11;

  // background: linear-gradient(90deg, #9C70FF 0%, #7C37FA 100%);

  transition: 600ms;

  .nav {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 97vw;
    background-color: white;
    border-radius: 24px;
    margin-top: 20px;
    padding: 9px 14px 32px;
  
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 80px;
      margin-bottom: 20px;
      // padding: 0 20px;

      .main-button {
        width: 32px;
        height: 32px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../Util/Images/Header/U.svg');
      }

      .back-button {
        width: 40px;
        height: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../Util/Images/Header/add.svg');

        cursor: pointer;
      }
    }

    &__menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: fit-content;

      a {
        text-decoration: none;
      }

      .menu-item {
        display: flex;
        justify-content: center;
        @include Mixins.default-font-picker(18px,500,#270D56);
        padding: 15px 0;
        border-bottom: 1px solid #E5E3E9;
        cursor: pointer;

        &:hover {
          @include Mixins.default-font-picker(18px,700,#7C37FA);
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .downloads-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        @include Mixins.default-font-picker(16px,500,white);
        background: linear-gradient(90deg, #A882FF 0%, #6A1FFA 100%);
        border-radius: 15px;
        margin-top: 30px;
      }

      .selected {
        @include Mixins.default-font-picker(18px,700,#7C37FA);
      }
    }
  }
}

.hidden {
  transform: translateX(100%);
  transition: 500ms;

  // animation: backgroundTransition 300ms linear infinite;

  // @keyframes backgroundTransition {
  //   0% {
  //     opacity: 0;
  //   }

  //   100% {
  //     opacity: 1;
  //   }
  // }
}