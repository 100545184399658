@use '../../../Util/Styles/Mixins.scss';

.blur-background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateZ(4);
  z-index: 11;
}

.send-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 594px;
  height: 423px;
  border-radius: 30px;
  background-color: #fff;
  z-index: 12;
  margin: 0 auto;
  left: 50%;
  -webkit-transform: translate(-50%, 5%);
  transform: translate(-50%, 5%);

  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
  border-radius: 30px;

  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  @media (max-width: 920px) {
    width: 95vw;
  }

  .purple-top {
    width: 100%;
    height: 100px;
    background: linear-gradient(110.51deg, #9C70FF 1.15%, #7C37FA 100%);

    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-bottom: 90px;
  }

  .circle-image {
    position: absolute;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../../Util/Images/Vacancies/success.svg');
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 33px;
  }

  .title {
    @include Mixins.default-font-picker(24px,700,#7C37FA);
    line-height: 30px;
    text-align: center;
    margin-bottom: 10px;
  }

  .main-text {
    @include Mixins.default-font-picker(16px,500,#565656);
    margin-bottom: 25px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 50px;
    background: #E8DDF7;
    border-radius: 15px;

    @include Mixins.default-font-picker(14px,600,#27016C);

    transition: 300ms;
    cursor: pointer;
    
    &:hover {
      transform: scale(105%);
    }
  }
}