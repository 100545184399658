@use '../../../Util/Styles/Mixins.scss';

div.call-form {
  position: fixed;
  top: 0;
  bottom: 0;
  margin: 0 auto;

  left: 50%;
  transform: translate(-50%, 30%);

  width: 574px;
  height: 460px;

  padding: 50px 70px 50px 70px;

  overflow: hidden;
  background: #b6baff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 7px 20px rgba(91, 45, 149, 0.25);
  border-radius: 30px;
  z-index: 12;

  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  @media (max-width: 610px) {
    width: 95%;
    height: fit-content;
    padding: 20px 35px;
  }

  .title {
    @include Mixins.default-font-picker(24px,700,#7C37FA);
    line-height: 30px;
    margin-bottom: 10px;
  }

  .main-text {
    @include Mixins.default-font-picker(14px,500,#27016C);
    line-height: 26px;
    margin-bottom: 20px;
  }

  .text-input {
    width: 100%;
    height: 50px;
    outline: none;
    border-radius: 15px;
    background: #F2F0F9;

    padding: 15px 27px;

    @include Mixins.default-font-picker(16px,600,black);
    border: none;
    margin-bottom: 10px;
  }

  .send-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 280px;
    background: linear-gradient(90deg, #A882FF 0%, #6A1FFA 100%);
    border-radius: 15px;
    animation: pulseBtn infinite 3s;

    @include Mixins.default-font-picker(14px,600,white);
    transition: 300ms;
    cursor: pointer;

    &:hover {
      transform: scale(95%);
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }
}

@keyframes pulseBtn {

  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  margin: 0;
  padding: 0;

  li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

    &:nth-child(1) {
      left: 25%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
    }

    &:nth-child(2) {
      left: 10%;
      width: 20px;
      height: 20px;
      animation-delay: 2s;
      animation-duration: 12s;
    }

    &:nth-child(3) {
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 4s;
    }

    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
    }

    &:nth-child(5) {
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
    }

    &:nth-child(6) {
      left: 75%;
      width: 110px;
      height: 110px;
      animation-delay: 3s;
    }

    &:nth-child(7) {
      left: 35%;
      width: 150px;
      height: 150px;
      animation-delay: 7s;
    }

    &:nth-child(8) {
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
    }

    &:nth-child(9) {
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
    }

    &:nth-child(10) {
      left: 85%;
      width: 150px;
      height: 150px;
      animation-delay: 0s;
      animation-duration: 11s;
    }
  }
}

@keyframes animate {

  0%{
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100%{
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }

}