@use '../../Util/Styles/Mixins.scss';

.news-page {
  width: 100%;

  .flex-container {
    position: relative;
    display: flex;
    max-width: 1220px;

    margin: 0 auto;

    @media (max-width: 1220px) {
      padding: 0 14px;
    }
  }

  &__intro {
    height: 540px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../Util/Images/News/Rectangle 275.png');
    background-size: cover;

    .flex-container {
      flex-direction: column;
      height: 100%;
      padding-top: 150px;

      .return-button {
        padding-left: 30px;
        @include Mixins.default-font-picker(16px,600,white);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 10px;
        background-image: url('../../Util/Images/News/Arrow\ -\ Left\ 2.svg');
        margin-bottom: 15px;

        cursor: pointer;
        transition: 300ms;

        &:hover {
          background-position-x: 2px;
        }
      }

      .news-container {
        width: 468px;
        height: 230px;

        padding: 27px 30px;
        background-color: white;

        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
        border-radius: 40px;

        @media (max-width: 500px) {
          width: 100%;
          height: fit-content;
        }

        .date {
          @include Mixins.default-font-picker(14px,500,#ABA5B6);
          margin-bottom: 7px;
        }

        .title {
          @include Mixins.default-font-picker(24p,700,#27016C);
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 7px;
        }

        .main-text {
          @include Mixins.default-font-picker(14px,500,#27016C);
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 26px;
        }
      }
    }
  }

  &__article {
    height: fit-content;

    .flex-container {
      padding: 70px 0 100px;
      height: 100%;
      flex-direction: column;

      @media (max-width: 1220px) {
        padding: 70px 14px 100px;
      }

      .title {
        @include Mixins.default-font-picker(18px,700,#27016C);
        margin-bottom: 15px;
      }

      .main-text {
        @include Mixins.default-font-picker(16px,600,#27016C);
      }

      .paragraph {
        margin-bottom: 20px;
      }
    }
  }

  &__list {
    background: #E8DDF7;
    height: fit-content;
    padding-top: 140px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    
    .flex-container {
      height: 100%;
      flex-direction: column;
    }

    &--intro {
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;
      background-repeat: no-repeat;
      background-image: url('../../Util/Images/News/news.svg');
      background-position: center right;
      background-size: contain;

      @media (max-width: 768px) {
        background-size: 140px;
        background-position: right 20px bottom 40px;
      }

      @media (max-width: 600px) {
        background-size: 115px;
        background-position: right 20px bottom 2px;
      }

      .line  {
        width: 75px;
        height: 2px;
        background-color: #7C37FA;
        border: 2px solid #7C37FA;
        border-radius: 2px;
        margin-bottom: 20px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,#7C37FA);
        margin-bottom: 20px;
      }

      .main-text {
        @include Mixins.default-font-picker(16px,500,#0E0E0E);
        margin-bottom: 64px;

        @media (max-width: 768px) {
          margin-bottom: 110px;
        }
      }

      .cateogry-selector {
        display: flex;
        height: 43px;
        align-items: center;

        .category {
          height: 100%;
          padding: 0 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include Mixins.default-font-picker(14px,500,#27016C);
          margin-right: 14px;
          cursor: pointer;

          transition: 300ms;

          &:hover {
            transform: scale(95%);
          }
        }

        .selected {
          background-color: #7C37FA;
          border-radius: 16px;
          color: white;
        }
      }
    }

    &--news {
      width: 100%;
      height: fit-content;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: auto;
      column-gap: 30px;
      row-gap: 34px;
      margin-bottom: 100px;

      @media (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }

      .news-container {
        width: 100%;
        height: 100%;

        padding: 27px 30px;
        background-color: white;

        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
        border-radius: 40px;
        cursor: pointer;
        transition: 300ms;

        &:hover {
          transform: scale(105%);
        }

        .date {
          @include Mixins.default-font-picker(14px,500,#ABA5B6);
          margin-bottom: 7px;
        }

        .title {
          @include Mixins.default-font-picker(24p,700,#27016C);
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 7px;
        }

        .main-text {
          @include Mixins.default-font-picker(14px,500,#27016C);
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 26px;
        }
      }
    }
  }

  &__downloads {
    height: 900px;
    background-repeat: no-repeat;
   

    .flex-container {
      height: 100%;
      align-items: center;

      background-image: url('../../Util/Images/News/Ellipse\ 50.svg'),
      url('../../Util/Images/News/Ellipse\ 52.svg'),
      url('../../Util/Images/News/Ellipse\ 54.svg'),
      url('../../Util/Images/News/Ellipse\ 56.svg'),
      url('../../Util/Images/News/Ellipse\ 57.svg'),
      url('../../Util/Images/News/Ellipse\ 59.svg'),
      url('../../Util/Images/News/Vector 2 (Stroke).svg');
      background-repeat: no-repeat;
      background-position: 1106px 90px, 184px 760px, 652px 224px, 25px 455px, 1057px 794px, 753px 730px, 65px 35px;
    }
  }
}