@use '../../Util/Styles/Mixins.scss';

.no-match--page {
  width: 100%;
  height: 100vh;
  align-items: center;

  background-color:  #27016C;
  background-image: url('../../Util/Images/NoMatch/404.png'),url('../../Util/Images/NoMatch/Group 33603404.png'), url('../../Util/Images/NoMatch/background.svg');
  background-repeat: no-repeat;
  background-position: top 50% center, top 40% center, bottom;
  background-size: auto, auto, contain;

  position: sticky;
  

  @media (max-width: 1024px) {
    background-size: 200px, 200px, auto;
  }

  .message {
    margin: 500px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    height: fit-content;

    @media (max-width: 550px) {
      width: 100%;
    }

    .title {
      @include Mixins.default-font-picker(36px, 700, white);
      margin-bottom: 15px;
      width: 100%;
      text-align: center;
    }

    .main-text {
      @include Mixins.default-font-picker(18px, 500, white);
      margin-bottom: 15px;
      width: 100%;
      text-align: center;
    }

    a {
      text-decoration: none;
      height: 50px;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 210px;
      height: 50px;
      background: linear-gradient(90deg, #A882FF 0%, #6A1FFA 100%);
      border-radius: 18px;

      @include Mixins.default-font-picker(14px,600,white);
    }
  }
}