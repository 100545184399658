@use '../../Util/Styles/Mixins.scss';

.main-page {
  width: 100%;


  .flex-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 1220px;

    margin: 0 auto;

    @media (max-width: 1220px) {
      padding: 0 14px;
    }
  }

  .main-page__intro {
    height: 700px;
    position: relative;

    border-bottom-right-radius: 400px;
    background-color: #7C37FA;

    background-repeat: no-repeat;
  
    background-image: url('../../Util/Images/MainPage/intro/Ellipse\ 50.svg'), 
    url('../../Util/Images/MainPage/intro/Ellipse\ 56.svg'),
    url('../../Util/Images/MainPage/intro/Vector 2 (Stroke)-1.svg'),
    url('../../Util/Images/MainPage/intro/Vector 2 (Stroke).svg');
    background-position-y: 68px, 256px, 83px, 559px;
    background-position-x: right, 32px, 379px, 648px;

    @media (max-width: 1024px) {
      height: fit-content;
      border-bottom-right-radius: 0;
      padding-bottom: 60px;
    }

    .flex-container {
      padding: 230px 52px 0;
    }

    .line {
      width: 74px;
      height: 3px;
      background-image: url('../../Util/Images/MainPage/line.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 24px;
    }

    .main-page__text {

      .title {
        @include Mixins.default-font-picker(36px,700, white);
        margin-bottom: 24px;
        line-height: 50px;

        @media (min-width: 768px) {
          width: 70%;
         }

        @media (max-width: 1024px) {
          @include Mixins.default-font-picker(26px,700, white);
        }
      }

      .main-text {
        @include Mixins.default-font-picker(16px,500, white);
        margin-bottom: 16px;
        line-height: 30px;

        @media (min-width: 768px) {
          width: 70%;
         }

        @media (max-width: 1024px) {
          @include Mixins.default-font-picker(14px,500, white);
          margin-bottom: 60px;
        }
      }
    }

    .downloads {
      display: flex;

      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: space-evenly;
        height: 186px;
        background: #9869FE;
        padding-left: 20px;
        box-shadow: -24px 20px 50px rgba(84, 48, 209, 0.35);
        border-radius: 20px;

        @media (max-width: 425px) {
          padding-left: 0;
        }
      }

      @media (max-width: 425px) {
        align-items: center;
      }

      .button {
        width: 178px;
        height: 55px;

        background-repeat: no-repeat;
        background-size: cover;

        cursor: pointer;
        transition: 300ms;

        &:hover {
          transform: scale(95%);
        }
      }

      .apple-button {
        background-image: url('../../Util/Images/MainPage/apple-button.svg');
        margin-right: 30px;

        @media (max-width: 425px) {
          margin-right: 0;
        }
      }

      .android-button {
        background-image: url('../../Util/Images/MainPage/android-button.svg');
      }
    }

    .phone {
      position: absolute;
      width: 430px;
      height: 730px;
      top: 60px;
      right: -25px;

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url('../../Util/Images/MainPage/Rectanglephone.png');

      animation: phone-hover 14s ease-in-out infinite;

      @media (max-width: 1300px) {
        right: 0px;
      }

      @media (max-width: 1258px) {
        width: 320px;
        height: 425px;
        right: 27px;
        top: auto;
        bottom: -41px;
      }

      @media (max-width: 768px) {
        width: 180px;
        bottom: -115px;
        height: 425px;
        background-size: contain;
      }

      @media (max-width: 425px) {
        display: none;
      }
    }

    @keyframes phone-hover {
      50% {
        transform: scale(90%) rotate(8deg);
      }
    }
  }

  .main-page__intro-2 {
    height: 950px;
    background-repeat: no-repeat;
    background-image: url('../../Util/Images/MainPage/URBANIST-background.svg'), url('../../Util/Images/MainPage/Group 7-dots.svg');
    background-position: center bottom 50px, left 100px bottom 50px;
    padding-bottom: 150px;
    background-size: contain;

    @media (max-width: 1024px) {
      height: fit-content;
    }

    .flex-container {
      height: 100%;
      flex-direction: row;

      padding-top: 128px;
      align-items: center;

      @media (max-width: 1024px) {
        padding: 40px 14px;
        align-items: flex-start;
        flex-direction: column-reverse;
      }

      .images-block {
        position: relative;
        width: 100%;
        height: 100%;

        @media (max-width: 1024px) {
          height: 400px;
        }

        @media (max-width: 550px) {
          height: 500px;
        }

        .image-1 {
          position: absolute;
          left: 20px;
          width: 282px;
          height: 413px;
          background-color: white;
          box-shadow: -24px 20px 50px rgba(84, 48, 209, 0.35);
          border-radius: 20px;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url('../../Util/Images/MainPage/intro-2-add-image.png');

          transition: 500ms;

          @media (max-width: 550px) {
            width: 199px;
            height: 273px;
          }
        }

        .image-2 {
          position: absolute;
          left: 120px;
          top: 100px;
          width: 412px;
          height: 441px;
          border-radius: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url('../../Util/Images/MainPage/intro-2-main-image.png');

          transition: 1s;

          @media (max-width: 550px) {
            width: 264px;
            height: 294px;
          }

          @media (max-width: 400px) {
            width: 100%;
            left: auto;
            right: 0;
            top: 145px;
            background-size: cover;
          }
        }

        &:hover {
          .image-1 {
            left: -20px;
          }

          .image-2 {
            transform: scale(90%);
          }
        }
       }

      .intro-2__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;

        @media (max-width: 1024px) {
          margin-bottom: 50px;
        }

        .line {
          width: 75px;
          height: 1px;
          border: 3px solid #7C37FA;
          border-radius: 3px;
          margin-bottom: 24px;
          background-color: #7C37FA; 
        }

        .title {
          @include Mixins.default-font-picker(36px,700,#4A2391);
          margin-bottom: 24px;
          line-height: 50px;

          .title-span {
            color: #7C37FA;
            text-shadow: 3px 3px 0px #FFFFFF, 4px 4px 0px #7E38FF;
          }
        }

        .main-text {
          @include Mixins.default-font-picker(16px,500,#54466A);
          line-height: 30px;
        }
      }
    }
  }

  .main-page__services {
    height: 900px;
    position:relative;
    overflow:hidden;
    z-index: 2;

    @media (max-width: 1024px) {
      height: fit-content;
      background-color: #9C70FF;
      // padding-bottom: 40px;
    }
    

    &:before{
      content:'';
      position:absolute;
      bottom:80%;
      left:-25%;
      width:150%;
      height:100%;
      border-radius:100%;
      box-shadow: 0px 600px 0px 350px #9C70FF;
      z-index: 1;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .background-container {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 3;
      background-repeat: no-repeat;
      background-image: url('../../Util/Images/MainPage/wave.svg');
      background-position: bottom;
      background-size: contain;
    }

    .flex-container {
      flex-direction: row;
      align-items: center;
      height: 100%;
      z-index: 4;
      padding-top: 150px;
      gap: 100px;

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 40px 14px;
      }

      a {
        text-decoration: none;
      }

      .services__text {
        display: flex;
        flex-direction: column;
        flex-basis: 480px;
        height: 485px;
        
        @media (max-width: 1024px) {
          flex-basis: auto;
          height:  fit-content;
          background-color: #9C70FF;
        }
        
        .line {
          border: 3px solid #FFFFFF;
          width: 75px;
          height: 1px;
          background-color: white;
          border-radius: 3px;
          margin-bottom: 24px;
        }

        .title {
          @include Mixins.default-font-picker(36px,700,white);
          margin-bottom: 24px;
        }

        .main-text {
          @include Mixins.default-font-picker(16px,500,white);
          margin-bottom: 25px;
          line-height: 30px;
        }

        .see-all {
          width: 150px;
          @include Mixins.default-font-picker(14px,600,white);
          background-repeat: no-repeat;
          background-position: center right 20px;
          background-image: url('../../Util/Images/MainPage/Arrow - Left 2.svg');
          cursor: pointer;

          transition: 300ms;

          &:hover {
            background-position-x: right;
          }
        }
      }

      .services__types {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        width: 100%;
        height: 485px;

        @media (max-width: 1024px) {
          height: fit-content;
          display: flex;
          flex-direction: column;
        }

        .icon {
          width: 80px;
          height: 80px;
          background: linear-gradient(223.99deg, rgba(255, 255, 255, 0.8) 5.31%, #FFFFFF 94.4%);
          box-shadow: -25px 0px 44px rgba(81, 49, 194, 0.35);
          border-radius: 20px;
          margin-bottom: 20px;

          background-size: 164px;
          background-position-x: -65px;
          background-position-y: -41px;
          background-repeat: no-repeat;
        }
        
        .type {
          display: flex;
          flex-direction: column;

          .title {
            @include Mixins.default-font-picker(18px,700,white);
            margin-bottom: 10px;
          }

          .main-text {
            @include Mixins.default-font-picker(14px,500,white);
            line-height: 26px;
          }
        }

        .subsription {
          background-image: url('../../Util/Images/MainPage/services/star.png');
        }

        .cards {
          background-image: url('../../Util/Images/MainPage/services/discount.png');
        }

        .products {
          background-image: url('../../Util/Images/MainPage/services/buy.png');
        }

        .fuel {
          background-image: url('../../Util/Images/MainPage/services/fuel.png');
        }
      }
    }
  }

  .main-page__coupons {
    height: 900px;
    background: linear-gradient(92.84deg, #FFFFFF 31.7%, rgba(255, 255, 255, 0) 95.47%), url('../../Util/Images/MainPage/Group 33524.svg') no-repeat;
    background-size: cover;

    @media (max-width: 1024px) {
      height: 818px;
      padding: 50px 0 200px;
      background: url('../../Util/Images/MainPage/coupons-mobile.svg') no-repeat bottom;
      background-size: contain;
    }

    @media (max-width: 425px) {
      height: fit-content;
    }

    
    .marquee {
      margin: 0 auto;
      width: 600px;
      height: 130px;
      overflow: hidden;
      position: relative;

      .marquee-inner {
        display: flex;
        align-items: center;
        width: 200%;
        height: 130px;
        animation: scroll 30s linear infinite;
        position: absolute;

        .row {
          display: flex;
          height: 130px;

          .row-item {
            width: 440px;
            height: 130px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url('../../Util/Images/MainPage/coupon(singular).svg');
          }
        }

        @keyframes scroll {
          0% { left: 0; }
          100% { left: -100%; }
        }
      }

      @media (max-width: 1000px) {
        
      }
    }

    .flex-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;

      @media (max-width: 768px) {
        justify-content: flex-start;
      }

      .line {
        width: 75px;
        border: 3px solid #7C37FA;
        height: 1px;
        background-color: #7C37FA;
        border-radius: 3px;
        margin-bottom: 24px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,#7C37FA);
        line-height: 50px;
        margin-bottom: 22px;

        @media (max-width: 768px) {
          @include Mixins.default-font-picker(26px,700,#7C37FA);
        }
      }

      .main-text {
        @include Mixins.default-font-picker(16px,500,#54466A);
        line-height: 30px;
      }
    }
  }

  .main-page__downloads {
    height: 900px;
    background-image: url('../../Util/Images/MainPage/wave-downloads.svg'),
    url('../../Util/Images/MainPage/wave-downloads-2.svg'),
    url('../../Util/Images/MainPage/Vector 2 (Stroke)-yellow.svg'),
    url('../../Util/Images/MainPage/Ellipse\ 56pink.svg'),
    url('../../Util/Images/MainPage/Ellipse\ 54green.svg'),
    url('../../Util/Images/MainPage/Ellipse\ 50pink-2.svg');
    background-size: cover,cover, 140px 87px,32px 32px,62px 62px,42px 42px,;
    background-position-y: 399px, 323px, 62px, 362px, 220px, 98px;
    background-position-x: center, center, 83px, 152px, 779px, 1171px;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      height: 556px;
    }

    .flex-container {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .main-page__news {
    height: 860px;
    background-color: #E8DDF7;
    background-repeat: no-repeat;
    background-image: url('../../Util/Images/MainPage/main-news-phone.png') ,url('../../Util/Images/MainPage/URBANIST (1).svg');
    background-position-y: -63px, 600px;
    background-position-x: 81px, 0px;
    background-size: auto, 1200px;
    position: relative;

    @media (max-width: 1378px) {
      background-position-x: 84px, 0px;
      background-position-y: 1px, 600px;
      background-size: 512px, contain;
    }

    @media (max-width: 1226px) {
      background-position-x: 11px, 0px;
    }

    @media (max-width: 1165px) {
      background-image: url('../../Util/Images/MainPage/URBANIST (1).svg');
      background-position: bottom 40px left;
      background-size: contain;
    }

    @media (max-width: 768px) {
      height: fit-content;
      padding-bottom: 100px;
    }

    .flex-container {
      display: grid;
      height: 100%;
      grid-template-columns: 430px 720px;
      grid-auto-rows: 1fr;
      position: relative;

      @media (max-width: 1226px) {
        grid-template-columns: 359px 720px;
      }

      @media (max-width: 1165px) {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
      }

      .news-article {
        display: flex;
        flex-direction: column;
        width: 100%;

        .line {
          width: 75px;
          height: 1px;
          background-color: #7C37FA;
          border-radius: 3px;
          border: 3px solid #7C37FA;
          margin-bottom: 20px;
        }

        .title {
          @include Mixins.default-font-picker(36px,700,#7C37FA);
          margin-bottom: 26px;
          line-height: 50px;
        }

        .main-text {
          @include Mixins.default-font-picker(16px,500,#4A2390);
          line-height: 30px;
          margin-bottom: 80px;
        }

        .parent {
          overflow: hidden;
          width: 792px;

          

          @media (max-width: 810px) {
            width: 100%;
          }

          @media (max-width: 380px) {
            height: fit-content;
          }

          .news-container {
            width: 386px;
            height: 250px;
            position: relative;
            padding: 26px 30px;

            @media (max-width: 420px) {
              width: 312px;
              height: 271px;
            }

            .date {
              @include Mixins.default-font-picker(14px,400,#ABA5B6);
            }

            .title {
              @include Mixins.default-font-picker(20px,700,#4A2390);
              line-height: 20px;
              margin-bottom: 8px;
            }

            .description {
              @include Mixins.default-font-picker(14px,500,#54466A);
              line-height: 22px;
              // margin-bottom: 20px;
            }

            .social {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              @include Mixins.default-font-picker(14px,500,#ABA5B6);

              .likes {
                background-repeat: no-repeat;
                background-position: center left;
                padding-left: 24px;
                background-image: url('../../Util/Images/MainPage/like.svg');
              }

              .reposts {
                background-repeat: no-repeat;
                background-position: center left;
                padding-left: 24px;
                background-image: url('../../Util/Images/MainPage/repost.svg');
              }
            }
          }
          
          .child {
            display: flex;
            flex-direction: column;
            background: white;
            width: 100%;
            margin-right: 10px;
            border-radius: 21px;
          }
        }
      }
    }
  }

  .main-page__advantages {
    position: relative;
    height: 980px;
    background-color: white;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    margin-top: -60px;

    @media (max-width: 1024px) {
      height: fit-content;
      padding-bottom: 70px;
      margin-top: 10px;
    }

    .flex-container {
      height: 100%;
      flex-direction: column;
      padding-top: 100px;

      @media (max-width: 1024px) {
        padding-top: 40px;
      }

      .line {
        width: 75px;
        height: 1px;
        background-color: #7C37FA;
        border: 2px solid #7C37FA;
        border-radius: 3px;
        margin-bottom: 20px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,#7C37FA);
        margin-bottom: 40px;
      }

      .grid-container {
        position: relative;
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 10px;
        row-gap: 35px;
        grid-auto-rows: 320px;

        @media (max-width: 1024px) {
          display: flex;
          flex-direction: column;
        }

        .advantages-phone {
          width: 281px;
          height: 353px;
          position: absolute;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url('../../Util/Images/About-us/advantages-phone.png');
          right: 0;

          @media (max-width: 1024px) {
            // height: 1022px;
            top: 459px;
            background-size: cover;
            height: 235px;
          }

          @media (max-width: 768px) {
            background-size: contain;
            width: 252px;
            right: 0px;
            background-position: center right -43px;
          }

          @media (max-width: 550px) {
            width: 216px;
            top: 549px;
          }

          @media (max-width: 370px) {
            top: 670px;
          }

        }

        .advantage-card {
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
          border-radius: 50px;
          padding: 56px 48px;

          .title {
            @include Mixins.default-font-picker(24px,700,#4A2390);
          }
        }

        .card-1 {
          grid-column: span 5;
        }

        .card-2 {
          grid-column: 6 / span 7;

          @media (min-width: 1024px) {
            padding-right: 215px;
          }

          .main-text {
            width: 70%;
          }
        }

        .card-3 {
          grid-column: span 7;
        }

        .card-4 {
          grid-column: span 5;
        }
      }
    }
  }

  .main-page__article {
    padding-top: 20px;
    height: 266px;

    @media (max-width: 768px) {
      // display: none;
    }

    @media (max-width: 1024px) {
      height: fit-content;
      margin-bottom: 40px;
    }

    .flex-container {
      flex-direction: column;

      .line {
        width: 75px;
        height: 2px;
        background-color: #4A2390;
        border: 2px solid #4A2390;
        border-radius: 2px;
        margin-bottom: 20px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,#4A2390);
        margin-bottom: 30px;
      }

      .article-block {
        display: flex;
        width: 100%;
        height: fit-content;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.05), 0px 5px 20px rgba(91, 45, 149, 0.15);
        border-radius: 30px;

        @media (max-width: 768px) {
          flex-direction: column;
          flex-basis: 0;
        }

        &__content {
          display: flex;
          flex-basis: 200px;
          flex-grow: 1;
          flex-shrink: 1;
          padding: 20px;
          align-items: center;

          
        @media (max-width: 768px) {
          flex-basis: 0;
        }

          .content__image {
            width:  130px;
            height: 130px;
            border-radius: 30px;
            background-image: url('../../Util/Images/Services/focus-logo.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 20px;

            @media (max-width: 1024px) {
              width: 80px;
              height: 80px;
              background-size: 60px;
            }
          }

          .content__text {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            @include Mixins.default-font-picker(24px,500,#27016C);

            @media (max-width: 1024px) {
              font-size: 16px;
            }
          }
        }

        &__button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          width: 170px;
          background: url('../../Util/Images/Services/Arrow - Left 2 (1).svg') no-repeat center right 30px, linear-gradient(90deg, #9C70FF 0%, #7C37FA 100%);
          // background-repeat: no-repeat;

          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;

          cursor: pointer;

          @include Mixins.default-font-picker(16px,600,white);
          transition: 300ms;

          @media (max-width: 768px) {
            width: 100%;
            height: 50px;
            border-radius: 0;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
          }

          // @media (max-width: 500px) {
          //   background: linear-gradient(90deg, #9C70FF 0%, #7C37FA 100%);
          //   font-size: 10px;
          // }

          &:hover {
            font-size: 20px;
            background: url('../../Util/Images/Services/Arrow - Left 2 (1).svg') no-repeat center right 20px, linear-gradient(90deg, #9C70FF 0%, #7C37FA 100%);
          }
        }
      }
    }
  }
}