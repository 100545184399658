@use '../../Util/Styles/Mixins.scss';

.faq-page {
  width: 100%;
  position: relative;

  .flex-container {
    position: relative;
    display: flex;
    max-width: 1220px;

    margin: 0 auto;

    @media (max-width: 1220px) {
      padding: 0 14px;
    }
  }

  &__intro {
    height: 420px;
    position: relative;
    z-index: 2;
    
    background: linear-gradient(110.51deg, #986AFF 1.15%, #9C70FF 65.51%, #7C37FA 100%);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    @media (max-width: 768px) {
      height: 510px;
    }

    .flex-container {
      padding-top: 170px;
      flex-direction: column;
      position: relative;
      z-index: 4;

      .line {
        width: 75px;
        height: 2px;
        background-color: white;
        border: 2px solid white;
        margin-bottom: 24px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,white);
        margin-bottom: 24px;
        line-height: 50px;
      }

      .main-text {
        @include Mixins.default-font-picker(16px,500,white);
        line-height: 30px;

        @media (min-width: 768px) {
          width: 50%;
        }
      }
    }
  }

  &__image {
    position: absolute;
    width: 450px;
    height: 376px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../Util/Images/FAQ/Splash_Screen_ill_1faq-octopus.svg');
    z-index: 3;
    right: 63px;
    top: 135px;

    @media (max-width: 1100px) {
      background-size: contain;
      width: 261px;
      height: 315px;
      right: 4px;
      top: 205px;
    }

    @media (max-width: 768px) {
      width: 248px;
      height: 315px;
      top: 323px;
     }

    @media (max-width: 320px) {
      // transform: scale(40%);
      right: -140px;
    }
  }

  &__questions {
    width: 100%;
    height: fit-content;

    @media (max-width: 768px) {
      border-radius: 0;
      margin-top: -84px;
    }

    .flex-container {
      padding-top: 60px;
      padding-bottom: 100px;

      @media (max-width: 768px) {
        padding: 0;
      }

      .questions-block {
        padding: 50px 72px;
        width: 100%;
        height: fit-content;

        background: #E8DDF7;
        border-radius: 50px;

        @media (max-width: 768px) {
          padding: 167px 14px 30px;
        }
      }
    }
  }

  &__bots {
    .flex-container {

      justify-content: center;

      @media (max-width: 768px) {
        padding-top: 50px;
      }

      @media (max-width: 570px) {
        padding-top: 30px;
        align-items: center;
        flex-direction: column;
      }

      .bot-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 270px;
        height: 122px;
        background: #FFFFFF;
        border: 2px solid #7C37FA;
        border-radius: 30px;

        background-repeat: no-repeat;
        background-position: center left 40px;
        padding-left: 40px;
        transition: 300ms;

        cursor: pointer;

        &:hover {
          transform: scale(95%);
        }
      }

      .telegram {
        margin-right: 30px;
        background-image: url('../../Util/Images/FAQ/Icon_telegram.svg');

        @media (max-width: 570px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      .viber {
        background-image: url('../../Util/Images/FAQ/Group 34836.svg');
      }
    }
  }

  &__contacts {
    height: 900px;
    width: 100%;

    @media (max-width: 1024px) {
      height: fit-content;
      padding: 30px 0;
    }

    .flex-container {
      height: 100%;
      align-items: center;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      .line {
        width: 75px;
        height: 2px;
        background-color: white;
        border: 2px solid white;
        margin-bottom: 24px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,white);
        margin-bottom: 24px;
        line-height: 50px;
      }

      .contacts-info {
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 575px;

        padding: 130px 100px;

        background: linear-gradient(110.51deg, #9C70FF 1.15%, #7C37FA 100%);
        border-radius: 50px;

        @media (max-width: 1024px) {
          padding: 75px 60px;
          height: fit-content;
          width: 100%;
        }

        @media (max-width: 768px) {
          padding: 51px 45px;
        }

        .contact {
          margin-bottom: 18px;

          &__title {
            @include Mixins.default-font-picker(16px,600,white);
            margin-bottom: 10px;
          }

          &__info {
            @include Mixins.default-font-picker(14px,400,white);
          }
        }
      }

      .downloads {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 630px;
        height: fit-content;
        right: 0;

        padding: 50px 70px 20px 70px;

        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
        border-radius: 30px;

        @media (max-width: 1220px) {
          width: 421px;
          right: 10px;
          padding: 50px 20px 20px 20px;
        }

        @media (max-width: 1024px) {
          position: relative;
          bottom: 0;
          left: 0;
          width: 100%;
          margin-top: -48px;
        }

        @media (max-width: 475px) {
          height: fit-content;
          bottom: 0;
        }

        .title {
          @include Mixins.default-font-picker(36px,700,#7C37FA);
          line-height: 50px;
          margin-bottom: 10px;
          text-align: center;
        }

        .main-text {
          @include Mixins.default-font-picker(14px,500,#27016C);
          line-height: 26px;
          margin-bottom: 40px;
          text-align: center;
        }

        .download-buttons {
          width: 100%;
          display: flex;
          justify-content: space-between;

          @media (max-width: 1220px) {
            flex-direction: column;
            align-items: center;
          }


          @media (max-width: 1024px) {
            flex-direction: row;
            justify-content: space-around;
          }
          
          @media (max-width: 475px) {
            flex-direction: column;
            align-items: center;
          }

          .button {
            width: 200px;
            height: 62px;
            background-repeat: no-repeat;
            background-size: cover;
            transition: 300ms;

            @media (max-width: 570px) {
              width: 150px;
              height: 47px;
            }

            @media (max-width: 475px) {
              margin-bottom: 10px;
            }

            cursor: pointer;

            &:hover {
              transform: scale(95%);
            }
          }

          .apple-button {
            background-image: url('../../Util/Images/Footer/Button_apple.svg');
          }

          .android-button {
            background-image: url('../../Util/Images/Footer/Button_Android.svg');
          }
        }
      }
    }
  }
}