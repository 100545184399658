.slider-container--news {
  /* if you want to have a scrollbar, you can add overflow here */ 
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  scroll-behavior: smooth;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }

  @media (max-width: 380px) {
    height: fit-content;
  }
 }
 .slider-wrapper--news {
   display: flex;
   align-items: center;
   width: 100%;

   @media (max-width: 380px) {
    height: fit-content;
  }
 }
 .btn-news {
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   cursor: pointer;
   width: 42px;
   height: 42px;
   background: grey;
   text-align: center;
   color: white;
   border-radius: 14px;
   background-color: #7C37FA;
   top: 93px;

   
   @media (max-width: 1165px) {
    top: 200px;
  }

   @media (max-width: 768px) {
    top: 277px;
    display: none ;
  }


   @media (max-width: 366px) {
    top: 454px;
  }

   .arrow {
     width: 16px;
     height: 16px;
     background-position: center;
     background-repeat: no-repeat;
     background-image: url('../../Util/Images/MainPage/Arrow - Left 2-white.svg');
   }
 }
 .prev-news {
   right: 203px;

   .arrow {
     transform: rotate(180deg);
   }

   @media (max-width: 1165px) {
    right: 53px;
  }
 }
 .next-news {
  right: 153px;

  
  @media (max-width: 1165px) {
    right: 1px;
  }
 }
 .disable {
  //  opacity: 0.5;
  background-color: rgba(216, 0, 0, 0);
  pointer-events: none;

  .arrow {
    background-image: url('../../Util/Images/MainPage/Arrow - Left 2-purple.svg');
  }
 }

 .prev-news.disable {
   .arrow {
     transform: rotate(0deg);
   }
 }

 .next-news.disable {
    .arrow {
      transform: rotate(180deg);
    }
 }