@use '../../Util/Styles/Mixins.scss';

.our-clients {
  position: relative;
  width: 100%;

  height: 818px;
  background: #E8DDF7;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;

  margin-bottom: -60px;

  @media (max-width: 768px) {
    height: 941px;
  }

  @media (max-width: 425px) {
    height: 1080px;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1220px;

    margin: 0 auto;
    padding-bottom: 60px;

    padding-top: 50px;

    @media (max-width: 1220px) {
      padding: 40px 14px;
    }

    .menu {
      width: 180px;
      @media (max-width: 1024px) {
        padding-left: 20px;

      }

      .line {
        width: 75px;
        height: 5px;
        background-repeat: no-repeat;
        background-image: url('../../Util/Images/Footer/Line 3.svg');
        margin-bottom: 20px;
      }

      .title {
        @include Mixins.default-font-picker(36px, 700, #7C37FA);
        margin-bottom: 20px;
      }

      .client-button {
        display: flex;
        align-items: center;
        height: 43px;
        width: 150px;
        padding-left: 15px;

        border-top-right-radius: 21px;
        border-bottom-right-radius: 21px;

        background-position: center left 5px;
        background-repeat: no-repeat;
        background-image: url('../../Util/Images/Footer/purple-dot.svg');
        transition: 300ms;

        &::before {
          position: absolute;
          content: '';
          width: 20vw;
          height: 43px;
          background-color: rgba($color: #ffffff, $alpha: 0);
          left: 0;
          transition: 300ms;
          margin-left: -20vw;
        }


        cursor: pointer;

        &:hover {
          background-color: white;

          &::before {
            background-color: rgba($color: #ffffff, $alpha: 1);
          }
        }
      }

      .selected {
        background-color: white;

        &::before {
          background-color: rgba($color: #ffffff, $alpha: 1);
        }
      }
    }

    .client-logos {
      width: 100%;
      height: 490px;

      position: relative;

      .logo {
        position: absolute;
        border-radius: 50%;
        background-color: white;

        background-repeat: no-repeat;
        background-position: center;

        background-image: url('../../Util/Images/Footer/logo-placeholder.svg');

        border: 2px solid #7C37FA;
        transition: all 300ms cubic-bezier(.37, -0.57, .48, 1.46);

        &:hover {
          transform: scale(150%);
          // width: 200px;
          // height: 200px;
          // background-size: 80%;
          z-index: 3;
        }
      }


      .iqlab {
        @include Mixins.logo-params(80px, #E61C1C, '../../Util/Images/OurClients/iq-lab.svg');
      }

      .polistars {
        @include Mixins.logo-params(96px, black, '../../Util/Images/OurClients/polistarslogo.svg');
        left: 20%;
      }

      .soho {
        @include Mixins.logo-params(140px, white, '../../Util/Images/OurClients/sohologo-white.svg');
        left: 43%;
      }

      .teatrcomedy {
        @include Mixins.logo-params(80px, #F9F402, '../../Util/Images/OurClients/teatrcomedylogo-yellow.svg');
        right: 20%;
      }

      .frisor {
        @include Mixins.logo-params(150px, #053C43, '../../Util/Images/OurClients/frizor-green.svg');
        right: 0;
        background-size: 109px;
      }

      .forrest {
        @include Mixins.logo-params(151px, white, '../../Util/Images/OurClients/restlogo.png');
        left: 7%;
        top: 69px;
      }

      .bartolomeo {
        @include Mixins.logo-params(152px, #52351E, '../../Util/Images/OurClients//bartolomeologo.png');
        left: 30%;
        top: 101px;
      }

      .paneevino {
        @include Mixins.logo-params(183px, white, '../../Util/Images/OurClients/pane-e-vino.png');
        right: 30%;
        top: 101px;
        background-size: 116px;
      }

      .tsunami {
        @include Mixins.logo-params(116px, white, '../../Util/Images/OurClients/tsunami.png');
        right: 13%;
        top: 141px;
        background-size: 83px;
      }

      .tittan {
        @include Mixins.logo-params(92px, black, '../../Util/Images/OurClients/tittanlogo.png');
        top: 201px;
      }

      .gvozd {
        @include Mixins.logo-params(100px, white, '../../Util/Images/OurClients/gvozdlogo.png');
        left: 20%;
        top: 255px;
      }

      .loweswing {
        @include Mixins.logo-params(105px, black, '../../Util/Images/OurClients/loweswinglogo.png');
        right: 20%;
        top: 255px;
      }

      .eightcoffee {
        @include Mixins.logo-params(100px, white, '../../Util/Images/OurClients/eightcoffeelogo.png');
        right: 0;
        top: 216px;
      }

      .letchik {
        @include Mixins.logo-params(165px, white, '../../Util/Images/OurClients/leetchik.png');
        top: 317px;
      }

      .sushi {
        @include Mixins.logo-params(130px, black, '../../Util/Images/OurClients/sushilogo.png');
        left: 30%;
        top: 317px;
      }

      .chico {
        @include Mixins.logo-params(108px, white, '../../Util/Images/OurClients/chicologo.png');
        left: 50%;
        top: 317px;
      }

      .maximus {
        @include Mixins.logo-params(85px, white, '../../Util/Images/OurClients/maximuslogo.png');
        right: 25%;
        top: 375px;
      }

      .atmosfera {
        @include Mixins.logo-params(108px, white, '../../Util/Images/OurClients/atmosfera.png');
        background-size: contain;
        left: 50%;
        top: 317px;
      }

      .neftek {
        @include Mixins.logo-params(100px, white, '../../Util/Images/OurClients/neftek.png');
        background-size: contain;
        right: 25%;
        top: 375px;
      }

      .nail {
        @include Mixins.logo-params(134px, white, '../../Util/Images/OurClients/nailservicelogo.png');
        right: 5%;
        top: 319px;
      }

      @media (max-width: 768px) {
        .iqlab {
          @include Mixins.logo-params(60px, #E61C1C, '../../Util/Images/OurClients/iq-lab.svg');
        }

        .polistars {
          @include Mixins.logo-params(80px, black, '../../Util/Images/OurClients/polistarslogo.svg');
          left: 20%;
        }

        .soho {
          @include Mixins.logo-params(100px, white, '../../Util/Images/OurClients/sohologo-white.svg');
          left: 43%;
          top: 82px;
        }

        .teatrcomedy {
          @include Mixins.logo-params(60px, #F9F402, '../../Util/Images/OurClients/teatrcomedylogo-yellow.svg');
          right: 28%;
        }

        .frisor {
          @include Mixins.logo-params(110px, #053C43, '../../Util/Images/OurClients/frizor-green.svg');
          right: 0;
          background-size: 109px;
        }

        .forrest {
          @include Mixins.logo-params(110px, white, '../../Util/Images/OurClients/restlogo.png');
          left: 0;
          top: 96px;
        }

        .bartolomeo {
          @include Mixins.logo-params(112px, #52351E, '../../Util/Images/OurClients//bartolomeologo.png');
          left: 30%;
          top: 200px;
        }

        .paneevino {
          @include Mixins.logo-params(120px, white, '../../Util/Images/OurClients/pane-e-vino.png');
          right: 31%;
          top: 297px;
          background-size: 75px;
        }

        .tsunami {
          @include Mixins.logo-params(80px, white, '../../Util/Images/OurClients/tsunami.png');
          right: 13%;
          top: 141px;
          background-size: 83px;
        }

        .tittan {
          @include Mixins.logo-params(80px, black, '../../Util/Images/OurClients/tittanlogo.png');
          top: 366px;
        }

        .gvozd {
          @include Mixins.logo-params(80px, white, '../../Util/Images/OurClients/gvozdlogo.png');
          left: 3%;
          top: 255px;
        }

        .loweswing {
          @include Mixins.logo-params(60px, black, '../../Util/Images/OurClients/loweswinglogo.png');
          right: 20%;
          top: 255px;
        }

        .eightcoffee {
          @include Mixins.logo-params(60px, white, '../../Util/Images/OurClients/eightcoffeelogo.png');
          right: 0;
          top: 216px;
        }

        .letchik {
          @include Mixins.logo-params(120px, white, '../../Util/Images/OurClients/leetchik.png');
          top: 545px;
        }

        .sushi {
          @include Mixins.logo-params(90px, black, '../../Util/Images/OurClients/sushilogo.png');
          left: 30%;
          top: 479px;
        }

        .chico {
          @include Mixins.logo-params(80px, white, '../../Util/Images/OurClients/chicologo.png');
          left: 50%;
          top: 583px;
        }

        .atmosfera {
          @include Mixins.logo-params(80px, white, '../../Util/Images/OurClients/atmosfera.png');
          background-size: contain;
          left: 50%;
          top: 583px;
        }

        .maximus {
          @include Mixins.logo-params(50px, white, '../../Util/Images/OurClients/maximuslogo.png');
          right: 25%;
          top: 505px;
        }

        .neftek {
          @include Mixins.logo-params(50px, white, '../../Util/Images/OurClients/neftek.png');
          background-size: contain;
          right: 25%;
          top: 505px;
        }

        .nail {
          @include Mixins.logo-params(90px, white, '../../Util/Images/OurClients/nailservicelogo.png');
          right: 5%;
          top: 386px;
        }
      }

      @media (max-width: 425px) {
        .iqlab {
          @include Mixins.logo-params(60px, #E61C1C, '../../Util/Images/OurClients/iq-lab.svg');
          top: 40px;
        }

        .polistars {
          @include Mixins.logo-params(80px, black, '../../Util/Images/OurClients/polistarslogo.svg');
          left: 20%;
          top: 100px;
        }

        .soho {
          @include Mixins.logo-params(100px, white, '../../Util/Images/OurClients/sohologo-white.svg');
          left: 31%;
          top: 6px;
        }

        .teatrcomedy {
          @include Mixins.logo-params(60px, #F9F402, '../../Util/Images/OurClients/teatrcomedylogo-yellow.svg');
          right: 20%;
        }

        .frisor {
          @include Mixins.logo-params(110px, #053C43, '../../Util/Images/OurClients/frizor-green.svg');
          right: 0;
          top: 136px;
          background-size: 77px;
        }

        .forrest {
          @include Mixins.logo-params(110px, white, '../../Util/Images/OurClients/restlogo.png');
          left: 0;
          top: 195px;
        }

        .bartolomeo {
          @include Mixins.logo-params(112px, #52351E, '../../Util/Images/OurClients//bartolomeologo.png');
          left: 30%;
          top: 303px;
        }

        .paneevino {
          @include Mixins.logo-params(120px, white, '../../Util/Images/OurClients/pane-e-vino.png');
          right: 31%;
          top: 443px;
        }

        .tsunami {
          @include Mixins.logo-params(80px, white, '../../Util/Images/OurClients/tsunami.png');
          right: 13%;
          top: 274px;
          background-size: 59px;
        }

        .tittan {
          @include Mixins.logo-params(80px, black, '../../Util/Images/OurClients/tittanlogo.png');
          top: 504px;
        }

        .gvozd {
          @include Mixins.logo-params(80px, white, '../../Util/Images/OurClients/gvozdlogo.png');
          left: 3%;
          top: 387px;
        }

        .loweswing {
          @include Mixins.logo-params(60px, black, '../../Util/Images/OurClients/loweswinglogo.png');
          right: 6%;
          top: 495px;
        }

        .eightcoffee {
          @include Mixins.logo-params(60px, white, '../../Util/Images/OurClients/eightcoffeelogo.png');
          right: 20px;
          top: 374px;
        }

        .letchik {
          @include Mixins.logo-params(120px, white, '../../Util/Images/OurClients/leetchik.png');
          top: 697px;
        }

        .sushi {
          @include Mixins.logo-params(90px, black, '../../Util/Images/OurClients/sushilogo.png');
          left: 30%;
          top: 603px;
        }

        .chico {
          @include Mixins.logo-params(80px, white, '../../Util/Images/OurClients/chicologo.png');
          left: 50%;
          top: 700px;
        }

        .atmosfera {
          @include Mixins.logo-params(80px, white, '../../Util/Images/OurClients/atmosfera.png');
          background-size: contain;
          left: 50%;
          top: 700px;
        }

        .maximus {
          @include Mixins.logo-params(50px, white, '../../Util/Images/OurClients/maximuslogo.png');
          right: 5%;
          top: 740px;
        }

        .neftek {
          @include Mixins.logo-params(5px, white, '../../Util/Images/OurClients/neftek.png');
          background-size: contain;
          right: 5%;
          top: 740px;
        }

        .nail {
          @include Mixins.logo-params(90px, white, '../../Util/Images/OurClients/nailservicelogo.png');
          right: 5%;
          top: 593px;
        }
      }

      @media (max-width: 320px) {
        .frisor {
          top: 60px;
        }
      }
    }
  }
}