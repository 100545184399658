@use '../../Util/Styles/Mixins.scss';

.about-us {
  width: 100%;

  .flex-container {
    position: relative;
    display: flex;
    max-width: 1220px;
    height: 100%;

    margin: 0 auto;

    @media (max-width: 1220px) {
      padding: 0 14px;
    }
  }

  &__info {
    height: 900px;
    
    @media (max-width: 1220px) {
      height: fit-content;
    }

    .flex-container {
      flex-direction: column;
      align-self: center;
    }

    .info-block {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 620px;
      margin-top: 150px;
      padding-left: 100px;
      margin-bottom: 60px;

      background: linear-gradient(110.51deg, #986AFF 1.15%, #9C70FF 65.51%, #7C37FA 100%);
      border-radius: 50px;

      @media (max-width: 1220px) {
        height: fit-content;
        padding: 60px 100px 100px;
      }

      @media (max-width: 1024px) {
        padding: 60px 14px 437px;
        padding-bottom: 437px;
      }

      &__text {
        position: relative;
        width: 50%;
        z-index: 2;

        @media (max-width: 1024px) {
          width: 100%;
        }

        .line {
          width: 75px;
          height: 2px;
          background-color: white;
          border: 2px solid white;
          border-radius: 2px;
          margin-bottom: 24px;
        }

        .title {
          @include Mixins.default-font-picker(36px,700,white);
          line-height: 50px;
          margin-bottom: 24px;
        }

        .main-text {
          @include Mixins.default-font-picker(16px,500,white);
          line-height: 30px;

          @media (max-width: 768px) {
            @include Mixins.default-font-picker(14px,500,white);
          }
        }
      }

      .animated-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 450px;
        height: 100%;
        position: absolute;
        right: 50px;
        z-index: 1;

        @media (max-width: 1024px) {
          right: 50%;
          bottom: 75px;
          height: fit-content;
          transform: translateX(50%);
        }

        @media (max-width: 768px) {
          width: fit-content;
        }
  
        .u-image {
          width: 310px;
          height: 310px;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../../Util/Images/About-us/U.svg');
          margin-bottom: 40px;
          animation: u-hover 10s ease-in-out infinite;

          @media (max-width: 1024px) {
            width: 200px;
            height: 200px;
            background-size: contain;
          }
        }
        
        .u-shadow {
          width: 100%;
          height: 27px;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('../../Util/Images/About-us/U-shadow.svg');
          animation: shadow-transform 10s ease-in-out infinite;

          @media (max-width: 1024px) {
            background-size: contain;
          }
        }
  
        @keyframes u-hover {
          0% {
            transform: translateY(0);
          }
  
          50% {
            transform: translateY(-50px);
          }
        }
  
        @keyframes shadow-transform {
          0% {
            transform: scale(100%);
          }
  
          50% {
            transform: scale(60%);
          }
        }
      }
    }

    .info-block__plus {
      position: relative;
      width: 100%;
      height: 170px;
      top: -90px;
      display: flex;

      @media (max-width: 1024px) {
        height: fit-content;
        flex-direction: column;
        // align-items: center;
      }

      .plus-block {
        height: 100%;
        width: fit-content;

        padding: 30px 40px;

        background-color: white;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
        border-radius: 30px;
        margin-left: 30px;
        
        @media (max-width: 1024px) {
          margin-left: 0;
          margin-bottom: 20px;
        }

        @media (max-width: 786px) {
          width: 100%;
        }

        &__title {
          @include Mixins.default-font-picker(18px,700,#27016C);
          margin-bottom: 10px;
        }

        &__main-text {
          @include Mixins.default-font-picker(14px,500,#27016C);
        }
      }

      .plus-block:first-child {
        margin-left: 100px;

        @media (max-width: 1024px) {
          margin-left: 0;
        }
      }
    }
  }

  &__story {
    width: 100%;
    display: flex;
    height: 900px;

    @media (max-width: 1024px) {
      height: fit-content;
      flex-direction: column;
      padding: 0 14px;
    }

    .selected {
      flex-grow: 1;

      padding: 170px 100px 70px;

      
      @media (max-width: 1540px) {
        padding: 87px 100px 30px;
      }

      @media (max-width: 1220px) {
        padding: 60px 63px 28px;
      }
    }

    .block {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 250px;
      height: 100%;
      transition: 300ms;


      @media (max-width: 1024px) {
        -webkit-border-bottom-right-radius: 20px;
        -webkit-border-bottom-left-radius: 20px;
        -moz-border-radius-bottomright: 20px;
        -moz-border-radius-bottomleft: 20px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;

        padding: 60px;
        width: 100%;
      }

      @media (max-width: 768px) {
        padding: 34px 15px 20px;
      }

      .button {
        position: absolute;
        width: 66px;
        height: 66px;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        cursor: pointer;
        z-index: 6;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      .next {
        background-image: url('../../Util/Images/About-us/arrow-right.svg');
        right: -33px;

        @media (max-width: 1540px) {
          top: 151px;
        }
      }

      .prev{
        background-image: url('../../Util/Images/About-us/arrow-right.svg');
        transform: rotate(180deg);
        top: 75px;
        right: -33px;
      }
    }

    .block-1 {
      background: #E8DDF7;
      border-top-right-radius: 50px;
      z-index: 4;
      
      @media (max-width: 1024px) {
        border-radius: 20px;
      }

      .content {
        .icon {
          background-image: url('../../Util/Images/About-us/01.svg');
        }
      }
    }
    .block-2 {
      background: #C09DF9;
      border-top-right-radius: 50px;
      margin-left: -50px;
      z-index: 3;

      @media (max-width: 1024px) {
        border-top-right-radius: 0;
        margin-top: -20px;
        margin-left: auto;
      }

      .content {
        .icon {
          background-image: url('../../Util/Images/About-us/02.svg');
        }
      }
    }
    .block-3 {
      background: #A676FF;
      border-top-right-radius: 50px;
      margin-left: -50px;
      z-index: 2;

      @media (max-width: 1024px) {
        border-top-right-radius: 0;
        margin-top: -20px;
        margin-left: auto;
      }
      
      .content {
        .icon {
          background-image: url('../../Util/Images/About-us/03.svg');
        }
      }
    }
    .block-4 {
      background: #7C37FA;
      z-index: 1;
      margin-left: -50px;

      @media (max-width: 1024px) {
        border-top-right-radius: 0;
        margin-top: -20px;
        margin-left: auto;

      }

      .content {
        .icon {
          background-image: url('../../Util/Images/About-us/04.svg');
        }
      }

      .prev {
        right: 1px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 0;

      transition: 300ms;
      overflow: hidden;
      visibility: hidden;

      @media (max-width: 1024px) {
        visibility: visible;
        height: fit-content;
      }

      .line {
        width: 75px;
        height: 2px;
        background-color: #27016C;
        border: 2px solid #27016C;
        margin-bottom: 33px;
      }

      .title {
        @include Mixins.default-font-picker(36px, 700, #27016C);
        margin-bottom: 150px;

        @media (max-width: 1220px) {
          margin-bottom: 50px;
        }
      }

      .icon {
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 30px;
      }

      .sub-title {
        @include Mixins.default-font-picker(18px,700,#27016C);
        margin-bottom: 10px;
      }

      .main-text {
        @include Mixins.default-font-picker(16px,500,#27016C);
        line-height: 30px;
      }
    }

    .content-selected {
      height: 100%;
      visibility: visible;
    }

    .block-3, .block-4 {
      .content {
        .line {
          background-color: white;
          border: 2px solid white;
        }
        
        .title {
          color: white;
        }

        .sub-title {
          color: white;
        }

        .main-text {
          color: white;
        }
      }
    }
  }

  &__advantages {
    position: relative;
    height: 980px;

    @media (max-width: 1024px) {
      height: fit-content;
    }

    .flex-container {
      height: 100%;
      flex-direction: column;
      padding-top: 100px;

      .line {
        width: 75px;
        height: 1px;
        background-color: #7C37FA;
        border: 2px solid #7C37FA;
        border-radius: 3px;
        margin-bottom: 20px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,#7C37FA);
        margin-bottom: 40px;
      }

      .grid-container {
        position: relative;
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 10px;
        row-gap: 35px;
        grid-auto-rows: 280px;

        @media (max-width: 1024px) {
          display: flex;
          flex-direction: column;
        }

        .advantages-phone {
          width: 281px;
          height: 353px;
          position: absolute;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url('../../Util/Images/About-us/advantages-phone.png');
          right: 0;

          @media (max-width: 1024px) {
            // height: 1022px;
            top: 459px;
            background-size: cover;
            height: 235px;
          }

          @media (max-width: 768px) {
            width: 206px;
            right: 0;
            top: 532px;
            background-size: contain;
          }

          @media (max-width: 370px) {
            top: 769px;
          }
        }

        .advantage-card {
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
          border-radius: 50px;
          padding: 56px 48px;

          .title {
            @include Mixins.default-font-picker(24px,700,#4A2390);
          }
        }

        .card-1 {
          grid-column: span 5;
        }

        .card-2 {
          grid-column: 6 / span 7;
          .main-text {
            @media (min-width: 1024px) {
              width: 70%;
            }
          }
        }

        .card-3 {
          grid-column: span 7;
        }

        .card-4 {
          grid-column: span 5;
        }
      }
    }
  }

  &__downloads {
    height: 850px;

    background-image: url('../../Util/Images/News/Ellipse\ 50.svg'),
    url('../../Util/Images/News/Ellipse\ 52.svg'),
    url('../../Util/Images/News/Ellipse\ 54.svg'),
    url('../../Util/Images/News/Ellipse\ 56.svg'),
    url('../../Util/Images/News/Ellipse\ 57.svg'),
    url('../../Util/Images/News/Ellipse\ 59.svg'),
    url('../../Util/Images/News/Vector 2 (Stroke).svg');
    background-repeat: no-repeat;
    background-position: 1234px 90px, 272px 760px, 696px 224px, 187px 455px, 1057px 794px, 918px 716px, 61px 35px;

    .flex-container {
      @media (max-width: 1220px) {
        padding: 0 14px;
      }
    }

    @media (max-width: 1024px) {
      height: 568px;
    }

    @media (max-width: 768px) {
      height: 458px;
    }
  }
}