@use '../../Util/Styles/Mixins.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 731px;

  @media (max-width: 768px) {
    height: 527px;
  }

  .background-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 1000px;

    background-repeat: no-repeat;
    background-image: url('../../Util/Images/Downloads/part-3.svg') ,url('../../Util/Images/Downloads/octopus-main.svg');
    background-size: 20% 20% ,45% 45%;
    background-position: 241px 143px, -18px 53px;

    @media (max-width: 1024px) {
      background-size: 100px, 200px;
      background-image:  url('../../Util/Images/Downloads/part-3-mobile.svg'), url('../../Util/Images/Downloads/octopus-mobile.svg');
      background-position: right 74px top 183px, right 86px top 130px;
    }

    @media (max-width: 768px) {
      background-position: right 74px top 85px, right 86px top 33px;
    }

    @media (max-width: 445px) {
      background-position: right 74px top 60px, right 86px top 13px;
    }
    
  
    .downloads {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 730px;
      height: 285px;
      border: 5px solid #9C70FF;
      background-color: white;
      border-radius: 50px;
      padding: 50px 14px;

      @media (max-width: 768px) {
        width: 100%;
        height: fit-content;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,#7C37FA);
        margin-bottom: 6px;
        text-align: center;

        @media (max-width: 768px) {
          text-align:start;
          width: 100%;
        }
      }

      .sub-title {
        text-align: center;
        @include Mixins.default-font-picker(14px,500,#27016C);
        margin-bottom: 30px;

        @media (max-width: 768px) {
          font-size: 12px;
          text-align:start;
          width: 100%;
        }
      }

      .download-buttons {
        display: flex;
        gap: 15px;

        .button {
          height: 62px;
          width: 200px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          
          cursor: pointer;
          transition: 300ms;

          @media (max-width: 460px) {
            height: 41px;
            width: 118px;
          }

          &:hover {
            transform: scale(90%);
          }
        }

        .apple-button {
          background-image: url('../../Util/Images/Downloads/Button_apple.svg');
        }

        .android-button {
          background-image: url('../../Util/Images/Downloads/Button_Android.svg');
        }
      }
    }
    
    .part-1 {
      position: absolute;
      width: 151px;
      height: 153px;
      background-repeat: no-repeat;
      background-image: url('../../Util/Images/Downloads/part-1.svg');
      top: 221px;
      left: 98px;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .part-2 {
      position: absolute;
      width: 92px;
      height: 208px;
      background-repeat: no-repeat;
      background-image: url('../../Util/Images/Downloads/part-2.svg');
      background-size: contain;
      top: 198px;
      left: 221px;

      @media (max-width: 1024px) {
        width: 55px;
        height: 208px;
        top: 209px;
        left: auto;
        right: 135px;
      }

      @media (max-width: 768px) {
        top: 106px;
      }

      @media (max-width: 445px) {
        top: 81px;
        right: 135px;
      }
    }
  }
}