@use '../../Util/Styles/Mixins.scss';

.accordion-wrapper {
  & + * {
    margin-top: 16px;
  }
}

.accordion-item {
  overflow: hidden;
  // transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  // transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  cursor: pointer;
  padding: 0.5em 1.5em;
  background-color: white;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include Mixins.default-font-picker(18px,700,#4A2390);

  background-repeat: no-repeat;
  background-position: center right 20px;
  background-image: url('../../Util/Images/Accordion/accordion-down.svg');

  min-height: 100px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding-right: 68px;
  }

  &.open {
    border-radius: 0%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    background-image: url('../../Util/Images/Accordion/accordion-up.svg');
  }
}

.accordion-content {
  padding: 1em 1.5em;
  padding-bottom: 30px;
  @include Mixins.default-font-picker(14px,500,#54466A);
  line-height: 26px;

  background-color: white;

  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  @media (max-width: 1024px) {
    @include Mixins.default-font-picker(12px,500,#54466A);
  }
}
