@use '../../../Util/Styles/Mixins.scss';

.blur-background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateZ(4);
  z-index: 11;
}

.vacancies-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: -120px;
  bottom: 0;
  padding: 46px 59px;
  width: 712px;
  height: 692px;
  border-radius: 12px;
  background-color: #fff;
  z-index: 12;
  margin: 0 auto;
  left: 50%;
  -webkit-transform: translate(-50%, 20%);
  transform: translate(-50%, 20%);

  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
  border-radius: 30px;

  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

  @media (max-width: 920px) {
    width: 95vw;
    padding: 18px;
    
    top: -165px;
  }

  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translate(-50%, 20%);
    transform: translate(-50%, 20%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(-50%, 25%);
      transform: translate(-50%, 25%);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translate(-50%, 20%);
      transform: translate(-50%, 20%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translate(-50%, 25%);
      transform: translate(-50%, 25%);
      opacity: 1;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .title {
      display: flex;
      justify-content: center;
      width: 100%;
      @include Mixins.default-font-picker(24px,700,#4A2390);
      margin-bottom: 30px;
    }

    .label {
      @include Mixins.default-font-picker(16px,500,#27016C);
      margin-bottom: 8px;
    }

    .text-input {
      height: 50px;
      width: 100%;
      border: none;
      outline: none;
      padding: 14px 18px;
      background: #F2F0F9;
      border-radius: 15px;
      @include Mixins.default-font-picker(16px,500,black);
      margin-bottom: 14px;

      &::placeholder {
        @include Mixins.default-font-picker(16px,500,#ABA5B6);
      }
    }

    .required {
      background-position: right 18px center;
      background-repeat: no-repeat;
      background-image: url('../../../Util/Images/Vacancies/Text Email.svg');
    }

    .file {
      background-position: right 18px center;
      background-repeat: no-repeat;
      background-image: url('../../../Util/Images/Vacancies/document-upload.svg');
    }

    .file-name {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      width: 95%;
    }

    .file-input {
      display: none;
    }

    .placeholder {
      color: #ABA5B6;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
  
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        border-radius: 15px;
  
        cursor: pointer;
  
        transition: 300ms;
        
        &:hover {
          transform: scale(95%);
        }
      }
  
      .cancel-button {
        @include Mixins.default-font-picker(14px,600,#27016C);
        background: #E8DDF7;
        width: 180px;

        @media (max-width: 530px) {
          margin-right: 7px;
        }
      }
  
      .send-button {
        border: none;
        outline: none;
        @include Mixins.default-font-picker(14px,600,white);
        background: linear-gradient(90deg, #A882FF 0%, #6A1FFA 100%);
        width: 280px;
      }
    }
  }

  .info {
    height: fit-content;
    width: 100%;
  }

  .main-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;

    .title {
      @include Mixins.default-font-picker(24px,700,#4A2390);
    }

    .pay {
      @include Mixins.default-font-picker(18px,700,#7C37FA);
    }
  }

  .text-block {
    height: 463px;
    overflow-y: scroll;


  }

  .sub-title {
    @include Mixins.default-font-picker(16px,700,#27016C);
    margin-bottom: 10px;
  }

  .main-text {
    @include Mixins.default-font-picker(16px,500,#565656);
    margin-bottom: 15px;
  }

  .list-item {
    @include Mixins.default-font-picker(16px,500,#565656);
    margin-bottom: 5px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      border-radius: 15px;
      text-align: center;

      cursor: pointer;

      transition: 300ms;
      
      &:hover {
        transform: scale(95%);
      }
    }

    .cancel-button {
      @include Mixins.default-font-picker(14px,600,#27016C);
      background: #E8DDF7;
      width: 180px;
    }

    .send-button {
      @include Mixins.default-font-picker(14px,600,white);
      background: linear-gradient(90deg, #A882FF 0%, #6A1FFA 100%);
      width: 280px;
      outline: none;
      border: none;
    }
  }
}