@use '../../Util/Styles/Mixins.scss';

.contacts-page {
  width: 100%;
  background-image: url('../../Util/Images/Contacts/Rectangle 1051.svg');
  background-size: cover;
  background-position: center top 2px;
  margin-bottom: -50px;

  @media (max-width: 768px) {
    background-position:center top 0;
  }

  .flex-container {
    position: relative;
    display: flex;
    max-width: 1220px;

    margin: 0 auto;

    @media (max-width: 1220px) {
      padding: 0 14px;
    }
  }

  &__downloads {
    height: 900px;
    width: 100%;
    background-repeat: no-repeat;

    .flex-container {
      padding-top: 90px;
      height: 100%;
      align-items: center;

      @media (max-width: 1024px) {
        flex-direction: column;
        padding-top: 110px;
      }

      .line {
        width: 75px;
        height: 2px;
        background-color: white;
        border: 2px solid white;
        margin-bottom: 24px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,white);
        margin-bottom: 24px;
        line-height: 50px;
      }

      .contacts-info {
        display: flex;
        flex-direction: column;
        flex-basis: 950px;
        flex-shrink: 1;
        height: 575px;

        padding: 130px 100px;

        background: linear-gradient(110.51deg, #9C70FF 1.15%, #7C37FA 100%);
        border-radius: 50px;

        @media (max-width: 1024px) {
          width: 100%;
        }

        @media (max-width: 768px) {
          padding: 48px 28px;        
          flex-basis: 681px;
        }

        .contact {
          margin-bottom: 18px;

          &__title {
            @include Mixins.default-font-picker(16px,600,white);
            margin-bottom: 10px;
          }

          &__info {
            @include Mixins.default-font-picker(14px,400,white);
          }
        }
      }

      .downloads {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 630px;
        height: fit-content;
        right: 0;

        padding: 50px 70px 20px 70px;

        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
        border-radius: 30px;

        @media (max-width: 1220px) {
          width: 432px;
          // transform: scale(80%);
        }

        @media (max-width: 1024px) {
          position: absolute;
          right: 50%;
          transform: translateX(50%);
          bottom: 20px;
          width: 90%;
          height: 280px;
        }

        @media (max-width: 768px) {
          padding: 20px 28px;
          bottom: 128px;
          height: fit-content;
        }

        @media (max-width: 320px) {
          padding: 10px 20px;
        }
  

        .title {
          @include Mixins.default-font-picker(36px,700,#7C37FA);
          line-height: 50px;
          margin-bottom: 10px;
          text-align: center;

          @media (max-width: 768px) {
            font-size: 26px;
            line-height: 20px;
          }
        }

        .main-text {
          @include Mixins.default-font-picker(14px,500,#27016C);
          line-height: 26px;
          margin-bottom: 40px;
          text-align: center;

          @media (max-width: 430px) {
            margin-bottom: 10px;
          }
        }

        .download-buttons {
          width: 100%;
          display: flex;
          justify-content: space-between;

          @media (max-width: 1220px) {
            flex-direction: column;
            align-items: center;
          }

          @media (max-width: 1024px) {
            flex-direction: row;
            align-items: center;
          }

          @media (max-width: 425px) {
            flex-direction: column;
            align-items: center;
          }
          
          .button {
            width: 200px;
            height: 62px;
            background-repeat: no-repeat;
            background-size: cover;
            transition: 300ms;

            cursor: pointer;

            &:hover {
              transform: scale(95%);
            }

            @media (max-width: 500px) {
              width: 150px;
              height: 46px;
            }
          }

          .apple-button {
            background-image: url('../../Util/Images/Footer/Button_apple.svg');
          }

          .android-button {
            background-image: url('../../Util/Images/Footer/Button_Android.svg');
          }
        }
      }
    }
  }

  &__map {
    height: 900px;

    @media (max-width: 1024px) {
      padding-bottom: 80px;
    }

    .flex-container {
      height: 100%;
      align-items: center;

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .location-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 340px;
        height: 100%;

        .line {
          width: 75px;
          height: 2px;
          background-color: #4A2390;
          border: 2px solid #4A2390;
          border-radius: 2px;
          margin-bottom: 24px;
        }

        .title {
          @include Mixins.default-font-picker(36px, 700,#4A2390);
          margin-bottom: 24px;
        }

        .sub-title {
          @include Mixins.default-font-picker(16px,600,#54466A);
          margin-bottom: 10px;
        }

        .info-text {
          @include Mixins.default-font-picker(14px,400,#54466A);
          margin-bottom: 20px;
        }
      }

      .location-map {
        flex-basis: 670px;
        flex-grow: 1;
        height: 520px;

        @media (max-width: 1024px) {
          width: 100%;
          height: 100vw;
          flex-basis: 0;
        }
      }

      .map {
        width: 100%;
        height: 100%;
        border: 4px solid #FFFFFF;
        border-radius: 50px;
      }
    }
  }
}