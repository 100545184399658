.slider-container {
  /* if you want to have a scrollbar, you can add overflow here */ 
     overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 3px;
    scroll-behavior: smooth;
    // margin-left: -30px;

    @media (max-width: 505px) {
      width: 290px;
      margin-left: 0;
    }
 }
 .slider-wrapper {
   display: flex;
   align-items: center;
   width: 100%;

   @media (max-width: 505px) {
    width: 290px;
  }
 }
 .btn {
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   cursor: pointer;
   width: 42px;
   height: 42px;
   background: grey;
   text-align: center;
   color: white;
   border-radius: 14px;
   background-color: #7C37FA;
   top: 565px;

   @media (max-width: 1024px) {
    top: 516px;
   }

   @media (max-width: 425px) {
    top: 593px;
   }

   .arrow {
     width: 16px;
     height: 16px;
     background-position: center;
     background-repeat: no-repeat;
     background-image: url('../../Util/Images/MainPage/Arrow - Left 2-white.svg');
   }
 }
 .prev {
   right: 50px;

   .arrow {
     transform: rotate(180deg);
   }
 }
 .next {
   right: 1px;
 }
 .disable {
  //  opacity: 0.5;
  background-color: rgba(216, 0, 0, 0);
  pointer-events: none;

  .arrow {
    background-image: url('../../Util/Images/MainPage/Arrow - Left 2-purple.svg');
  }
 }

 .prev.disable {
   .arrow {
     transform: rotate(0deg);
   }
 }

 .next.disable {
    .arrow {
      transform: rotate(180deg);
    }
 }