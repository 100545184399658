@use '../../Util/Styles/Mixins.scss';

.footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 100%;
  height: 325px;
  padding: 70px 0 25px;

  background: linear-gradient(110.51deg, #9C70FF 1.15%, #7C37FA 100%);

  border-top-left-radius: 60px;
  border-top-right-radius: 60px;

  @media (max-width: 992px) {
    height: initial;
  }

  @media (max-width: 768px) {
    height: fit-content;
    padding-top: 35px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media (max-width: 1024px) {
      padding: 0 14px;
    }



    .main {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;

      @media (max-width: 992px) {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 30px;
        padding: 0 30px;
      }

      @media (max-width: 810px) {
        padding: 0;
        gap: 30px 10px;
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .title {
        @include Mixins.default-font-picker(16px,700,white);
        margin-bottom: 12px;
      }

      .logo-wrapper {

        @media (max-width: 992px) {
          width: 100%;
        }

        @media (max-width: 768px) {
          gap: 50px;
        }

        @media (max-width: 450px) {
          gap: 10px;
        }

        a {
          display: flex;
          gap: 25px;
          text-decoration: none;

          @media (max-width: 1140px) {
            gap: 10px;
          }
        }
        
        .logo-text {
          color: #fff;
          text-decoration: none;
          text-align: right;
          font-size: 38px;
          font-weight: 700;
          max-width: 210px;

          @media (max-width: 1140px) {
            font-size: 25px;
            max-width: 138px;
          }
          
          span {
            display: block;
            font-size: 13px;
            margin-top: 10px;

            @media (max-width: 1140px) {
              font-size: 10px;
            }
          }
        }


        @media (max-width: 768px) {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;

          margin-bottom: 22px;
        }

        .button-up {
          width: 40px;
          height: 40px;
          background-size: contain;
          background-image: url('../../Util/Images/Footer/Button_Back.svg');

          display: none;

          @media (max-width: 768px) {
            display: block;
          }
        }
      }

      .logo {
        width: 82px;
        height: 98px;

        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../Util/Images/Footer/logo-footer.svg');
        background-size: contain;
        cursor: pointer;

        @media (max-width: 1140px) {
          width: 57px;
          height: 67px;
        }

        @media (max-width: 768px) {
          //width: 183px;
          //height: 20px;
          //transform: scale(100%);
          //margin-right: 20px;
          //
          //background-image: url('../../Util/Images/Footer/URBANIST.svg');
          order: 1;
        }

      }

      .menu {
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          width: 100%;
          order: 3;
          padding-bottom: 24px;
          margin-bottom: 22px;

          border-bottom: 1px solid white;
        }

        &__container {
          display: grid;
          grid-template-columns: repeat(2,1fr);
          column-gap: 20px;
          row-gap: 10px;

          a {
            text-decoration: none;
          }

          .menu__link {
            @include Mixins.default-font-picker(14px,500,white);
            border-bottom: 2px solid rgba($color: white, $alpha: 0);

            cursor: pointer;
            transition: 300ms;

            &:hover {
              border-bottom: 2px solid rgba($color: white, $alpha: 1);
            }
          }
        }
      }

      .contacts {
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          order: 2;
          padding-bottom: 24px;
          margin-bottom: 22px;
          width: 100%;

          border-bottom: 1px solid white;
        }

        .contact {
          display: flex;
          align-items: center;
          height: 18px;

          background-repeat: no-repeat;
          background-position: center left;
          
          @include Mixins.default-font-picker(14px,500,white);
          margin-bottom: 12px;
          padding-left: 20px;
        }

        &__email {
          background-image: url('../../Util/Images/Footer/sms.svg');
        }

        &__phone {
          background-image: url('../../Util/Images/Footer/call.svg');
        }
      }

      .sub-menu {
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          order: 4;
          width: 100%;
        }

        &__links {
          display: grid;
          height: fit-content;
          grid-template-columns: 155px 68px;
          column-gap: 34px;
          margin-bottom: 20px;

          @media (max-width: 768px) {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          .app-download {
            width: 155px;
            height: 48px;

            cursor: pointer;
            background-position: center;
            background-size: cover;
            transition: 300ms;

            &:hover {
              transform: scale(105%);
            }
          }

          .social-links {
            width: 100%;
            height: 100%;

            display: grid;
            grid-template-columns: repeat(2, 24px);
            grid-template-rows: 24px;
            row-gap: 17px;
            column-gap: 20px;

            @media (max-width: 768px) {
              width: 72px;
            }
          }

          .appstore {
            background-image: url('../../Util/Images/Footer/Button_apple.svg');
            margin-bottom: 18px;
          }

          .google-play {
            background-image: url('../../Util/Images/Footer/Button_Android.svg');
          }

          .social-link {
            width: 24px;
            height: 24px;

            cursor: pointer;
            background-repeat: no-repeat;
            background-position: center;

            transition: 300ms;

            &:hover {
              transform: scale(105%);
            }
          }

          .linkedin {
            background-image: url('../../Util/Images/Footer/linkedin.svg');
          }

          .youtube {
            background-image: url('../../Util/Images/Footer/youtube.svg');
            background-size: contain;
          }

          .instagram {
            background-image: url('../../Util/Images/Footer/Group 34842instagram.svg');
          }

          .facebook {
            background-image: url('../../Util/Images/Footer/facebook.svg');
          }

          .twitter {
            background-image: url('../../Util/Images/Footer/twitter.svg');
          }

          .tiktok {
            background-image: url('../../Util/Images/Footer/tiktok.svg');
          }
        }

        &__translation {
          display: grid;
          grid-template-columns: repeat(3,1fr);
          height: 36px;

          // visibility: hidden;

          border: 1px solid #E8DDF7;
          border-radius: 40px;

          .position {
            display: flex;
            align-items: center;
            justify-content: center;
            @include Mixins.default-font-picker(14px,600,#E8DDF7);
            cursor: pointer;

          }

          .selected {
            background: #E8DDF7;
            border-radius: 40px;
            color: #7C37FA;

          }
        }
      }
    }

    .lower {
      height: 30px;
      @include Mixins.default-font-picker(12px,500,white);
      padding-left: 50px;

      @media (max-width: 768px) {
        margin-top: 25px;
        padding-left: 0;
        text-align: center;
      }
    }
  }
}

.hidden {
  display: none;
}