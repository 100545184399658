@use '../../Util/Styles/Mixins.scss';

.vacancies-page {
  width: 100%;

  @media (max-width: 1220px) {
    padding: 0 14px;
  }


  .flex-container {
    position: relative;
    display: flex;
    max-width: 1220px;
    height: 100%;

    margin: 0 auto;

    @media (max-width: 1220px) {
      padding: 0 14px;
    }
  }

  &__intro {
    height: 420px;
    background: linear-gradient(110.51deg, #986AFF 1.15%, #9C70FF 65.51%, #7C37FA 100%);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    @media (max-width: 1024px) {
      height: 511px;
      margin-bottom: 100px;
    }

    .flex-container {
      height: 100%;
      padding-top: 170px;
      flex-direction: column;

      .line {
        width: 75px;
        height: 2px;
        background-color: white;
        border: 2px solid white;
        margin-bottom: 24px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,white);
        margin-bottom: 24px;
        line-height: 50px;
      }

      .main-text {
        @include Mixins.default-font-picker(16px,500,white);
        line-height: 30px;
      }

      .text-container {
        position: relative;
        z-index: 1;
      }

      .image {
        position: absolute;
        width: 350px;
        height: 330px;
        background-position: center;
        background-repeat: no-repeat;
        right: 20px;
        background-image: url('../../Util/Images/Vacancies/Vacancies-image.svg');

        @media (max-width: 1024px) {
          background-size: contain;
          width: 208px;
          right: 29%;
          bottom: -122px;
        }

        @media (max-width: 768px) {
          right: 3px;
        }

        @media (max-width: 425px) {
          width: 178px;
        }
      }
    }
  }

  &__list {
    height: fit-content;

    .flex-container {
      height: 100%;
      padding-top: 30px;
      flex-direction: column;
      padding-bottom: 50px;

      .title {
        @include Mixins.default-font-picker(18px,700,#27016C);
        margin-bottom: 20px;
      }

      .filter-container {
        display: flex;
        flex-direction: row;
        height: 54px;
        max-width: 1010px;
        overflow-y: hidden;
        margin-bottom: 50px;

        @media (max-width: 1038px) {
          overflow-x: scroll;
        }

        .filter {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 43px;
          width: 178px;
          background: #E8DDF7;
          border-radius: 16px;
          transition: 300ms;
          flex-shrink: 0;

          margin-left: 30px;
          cursor: pointer;

          @media (max-width: 768px) {
            margin-left: 10px;
          }

          @include Mixins.default-font-picker(14px,500,#54466A);

          &:first-child {
            margin-left: 0;
          }
        }

        .selected {
          color: white;
          background: #7C37FA
        }
      }

      .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
        row-gap: 30px;
        grid-auto-rows: auto;

        @media (max-width: 1024px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .grid-item {
          position: relative;
          width: 100%;
          height: fit-content;
          background: #FFFFFF;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
          border-radius: 40px;

          padding: 25px 30px;

          @media (max-width: 320px) {
            height: fit-content;
          }

          .info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            @include Mixins.default-font-picker(14px,500,#ABA5B6);
            margin-bottom: 10px;
          }

          .title {
            @include Mixins.default-font-picker(18px,700,#27016C);
            margin-bottom: 10px;
          }

          .main-text {
            @include Mixins.default-font-picker(14px,500,#27016C);
            line-height: 26px;
            // height: 60px;
            margin-bottom: 30px;
          }

          .pay {
            @include Mixins.default-font-picker(18px,700,#7C37FA);
            position: absolute;
            left: 30px;
            bottom: 25px;
          }
        }
      }
    }
  }

  &__contacts {
    height: 900px;
    width: 100%;

    @media (max-width: 1024px) {
      height: fit-content;
      padding: 30px 0;
    }

    .flex-container {
      height: 100%;
      align-items: center;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      .line {
        width: 75px;
        height: 2px;
        background-color: white;
        border: 2px solid white;
        margin-bottom: 24px;
      }

      .title {
        @include Mixins.default-font-picker(36px,700,white);
        margin-bottom: 24px;
        line-height: 50px;
      }

      .contacts-info {
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 575px;

        padding: 130px 100px;

        background: linear-gradient(110.51deg, #9C70FF 1.15%, #7C37FA 100%);
        border-radius: 50px;

        
        @media (max-width: 1024px) {
          width: 100%;
          padding: 62px 49px;
          height: fit-content;
        }

        @media (max-width: 768px) {
          padding: 43px 35px;
        }

        .contact {
          margin-bottom: 18px;

          &__title {
            @include Mixins.default-font-picker(16px,600,white);
            margin-bottom: 10px;
          }

          &__info {
            @include Mixins.default-font-picker(14px,400,white);
          }
        }
      }

      .downloads {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 630px;
        height: fit-content;
        right: 0;

        padding: 50px 70px 20px 70px;

        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
        border-radius: 30px;

        @media (max-width: 1220px) {
          width: 411px;
          padding: 50px 30px 20px 30px;
          // transform: scale(80%);
        }

        @media (max-width: 1024px) {
          margin-top: -55px;
          position: relative;
          left: 0;
          width: 100%;
        }

        @media (max-width: 475px) {
          height: fit-content;
          bottom: 0;
        }

        .title {
          @include Mixins.default-font-picker(36px,700,#7C37FA);
          line-height: 50px;
          margin-bottom: 10px;
          text-align: center;
        }

        .main-text {
          @include Mixins.default-font-picker(14px,500,#27016C);
          line-height: 26px;
          margin-bottom: 40px;
          text-align: center;
        }

        .download-buttons {
          width: 100%;
          display: flex;
          justify-content: space-between;

          @media (max-width: 1220px) {
            flex-direction: column;
            align-items: center;
          }

          @media (max-width: 1024px) {
            flex-direction: row;
            justify-content: space-around;
          }
          
          @media (max-width: 475px) {
            flex-direction: column;
            align-items: center;
          }

          .button {
            width: 200px;
            height: 62px;
            background-repeat: no-repeat;
            background-size: cover;
            transition: 300ms;

            @media (max-width: 570px) {
              width: 150px;
              height: 47px;
            }

            @media (max-width: 475px) {
              margin-bottom: 10px;
            }

            cursor: pointer;

            &:hover {
              transform: scale(95%);
            }
          }

          .apple-button {
            background-image: url('../../Util/Images/Footer/Button_apple.svg');
          }

          .android-button {
            background-image: url('../../Util/Images/Footer/Button_Android.svg');
          }
        }
      }
    }
  }
}