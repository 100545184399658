@use '../../Util/Styles/Mixins.scss';

.partners-page {
  width: 100%;

  .flex-container {
    position: relative;
    display: flex;
    max-width: 1220px;

    margin: 0 auto;

    @media (max-width: 1220px) {
      padding: 0 14px;
    }
  }

  .line {
    width: 75px;
    height: 1px;
    border: 2px solid white;
    border-radius: 3px;
    background-color: white;
    margin-bottom: 24px;
  }

  .title {
    @include Mixins.default-font-picker(36px, 700, white);
    line-height: 50px;
    margin-bottom: 24px;
  }

  .main-text {
    @include Mixins.default-font-picker(16px, 500, white);
    line-height: 30px;
  }

  &__intro {
    width: 100%;
    height: 700px;
    position: relative;

    border-bottom-right-radius: 400px;
    background-color: #7C37FA;

    background-repeat: no-repeat;

    background-image: url('../../Util/Images/MainPage/intro/Ellipse\ 50.svg'),
    url('../../Util/Images/MainPage/intro/Ellipse\ 56.svg'),
    url('../../Util/Images/MainPage/intro/Vector 2 (Stroke)-1.svg'),
    url('../../Util/Images/MainPage/intro/Vector 2 (Stroke).svg');
    background-position-y: 68px, 256px, 83px, 559px;
    background-position-x: right, 32px, 379px, 648px;

    @media (max-width: 1024px) {
      border-radius: 0;
    }

    @media (max-width: 768px) {
      height: fit-content;
      padding-bottom: 40px;
    }

    .flex-container {
      padding: 230px 52px 0;
      flex-direction: column;

      @media (max-width: 1024px) {
        padding: 140px 52px 0;
      }

      .title {
        @media (min-width: 1024px) {
          width: 70%;
        }
      }

      .main-text {
        margin-bottom: 40px;
      }

      .downloads {
        display: flex;

        @media (max-width: 1024px) {
          height: 180px;
          flex-direction: column;
          justify-content: space-around;

          background: #9869FE;
          box-shadow: -24px 20px 50px rgba(84, 48, 209, 0.35);
          border-radius: 20px;
          padding: 20px;
        }

        @media (max-width: 455px) {
          align-items: center;
        }

        .button {
          width: 178px;
          height: 55px;

          background-repeat: no-repeat;
          background-size: cover;

          cursor: pointer;
          transition: 300ms;
          position: relative;
          z-index: 2;

          &:hover {
            transform: scale(95%);
          }
        }

        .apple-button {
          background-image: url('../../Util/Images/MainPage/apple-button.svg');
          margin-right: 30px;

          @media (max-width: 455px) {
            margin-right: 0;
          }
        }

        .contact-button {
          background-image: url('../../Util/Images/MainPage/contact-btn_bg.svg');
          background-size: cover;
          margin-right: 30px;
          border: none;
          border-radius: 7px;
          font-weight: 600;
          font-size: 14px;
          font-family: 'Montserrat', sans-serif;
          animation: pulse infinite 2s;

          @media (max-width: 425px) {
            margin-right: 0;
          }
        }

        .android-button {
          background-image: url('../../Util/Images/MainPage/android-button.svg');
        }
      }

      .phone {
        position: absolute;
        width: 430px;
        height: 730px;
        top: 60px;
        right: -25px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        background-image: url('../../Util/Images/Partners/phone-intro.png');

        animation: phone-hover 14s ease-in-out infinite;

        @media (max-width: 1300px) {
          right: 20px;
        }

        @media (max-width: 1024px) {
          height: 358px;
          width: 217px;
          background-size: contain;
          animation: phone-hover-scaled 14s ease-in-out infinite;
          bottom: -98px;
          top: auto;
        }

        @media (max-width: 455px) {
          height: 351px;
          width: 170px;
        }

        @media (max-width: 455px) {
          display: none;
        }
      }

      @keyframes phone-hover {
        50% {
          transform: scale(90%) rotate(8deg);
        }
      }

      @keyframes phone-hover-scaled {
        50% {
          transform: scale(90%) rotate(8deg);
        }
      }
    }
  }

  &__advantages {
    height: 800px;

    background-repeat: no-repeat;
    background-image: url('../../Util/Images/Partners/wave.svg');
    background-size: cover;
    background-position-y: 144px;

    @media (max-width: 768px) {
      height: fit-content;
    }

    .flex-container {
      height: 100%;
      // align-items: center;
      padding-top: 100px;

      background-repeat: no-repeat;
      background-image: url('../../Util/Images/Partners/Group 7-dots.svg'),
      url('../../Util/Images/Partners/Ellipse 57.svg'),
      url('../../Util/Images/Partners/Ellipse 52.svg');
      background-position-y: 516px, 8px, 159px;
      background-position-x: 1px, 1166px, 54px;

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 60px;
      }

      .advantages-text {
        display: flex;
        flex-direction: column;
        flex-basis: 380px;
        margin-right: 30px;

        @media (max-width: 1024px) {
          flex-basis: 180px;
        }

        .line {
          background-color: #27016C;
          border-color: #27016C;
        }

        .title {
          color: #27016C;
        }

        .main-text {
          color: #27016C;
        }
      }

      .advantages-cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 270px;
        row-gap: 20px;
        column-gap: 15px;

        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
          row-gap: 10px;
          grid-auto-rows: auto;
        }


        .card {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;

          .icon {
            width: 80px;
            height: 80px;
            background-position: center;
            background-size: cover;
            margin-bottom: 18px;
          }

          .title {
            @include Mixins.default-font-picker(18px, 700, #4A2390);
            line-height: 20px;
          }

          .main-text {
            @include Mixins.default-font-picker(14px, 500, #54466A);
            line-height: 25px;
          }
        }

        .profit {
          .icon {
            background-image: url('../../Util/Images/Partners/money.png');
          }
        }

        .coverage {
          .icon {
            background-image: url('../../Util/Images/Partners/discount.png');
          }
        }

        .bonuses {
          .icon {
            background-image: url('../../Util/Images/Partners/discount.png');
          }
        }

        .support {
          .icon {
            background-image: url('../../Util/Images/Partners/fuel.png');
          }
        }
      }
    }
  }

  &__opportunities {
    height: 750px;

    background-color: #F4EDFF;

    @media (max-width: 1024px) {
      height: fit-content;
      padding-top: 40px;
    }

    .flex-container {
      height: 100%;
      justify-content: flex-end;
      padding-top: 20px;

      background-repeat: no-repeat;
      background-image: url('../../Util/Images/Partners/opportunities-background.png');
      background-position: -64px -24px;

      @media (max-width: 1024px) {
        background-image: none;
        justify-content: flex-start;
      }

      .opportunities-list {
        height: 100%;
        width: 550px;

        .line {
          background-color: #7C37FA;
          border-color: #7C37FA;
        }

        .title {
          color: #7C37FA;
        }

        .mobile-image {

          @media (min-width: 1024px) {
            display: none;
          }

          width: 100%;
          height: 365px;

          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          background-image: url('../../Util/Images/Partners/opportunities-background.png');
        }

        .list {
          display: flex;
          flex-direction: column;

          .list-item {
            @include Mixins.default-font-picker(18px, 500, #27016C);
            padding-left: 44px;
            margin-bottom: 26px;

            background-repeat: no-repeat;
            background-position-x: left;
            background-position-y: 3px;
            background-image: url('../../Util/Images/Partners/checkmark.svg');
            line-height: 30px;
          }
        }

      }
    }
  }

  &__rates {
    background: #F4EDFF;

    height: fit-content;

    padding-bottom: 74px;

    .flex-container {
      flex-direction: column;
      height: fit-content;

      .line {
        border-color: #4A2390;
        background-color: #4A2390;
      }

      .title {
        color: #4A2390;
      }

      .rates-container {
        width: 100%;
        height: fit-content;

        display: flex;
        flex-direction: row;
        column-gap: 30px;
        justify-content: center;

        @media (max-width: 1024px) {
          flex-direction: column;
          grid-template-columns: 1fr;
          row-gap: 20px;
        }

        .rates-item {
          height: fit-content;
          height: -moz-fit-content;
          width: 380px;
          display: flex;
          flex-direction: column;
          align-items: center;


          // padding: 40px 28px;
          background-color: white;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
          border-radius: 30px;

          @media (max-width: 1024px) {
            width: 100%;
          }

          &__button {
            width: 200px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            background: linear-gradient(110.51deg, #986AFF 1.15%, #9C70FF 65.51%, #7C37FA 100%);
            border-radius: 30px;

            @include Mixins.default-font-picker(16px, 500, white);

            margin-bottom: 15px;

            cursor: pointer;

            transition: 300ms;

            &:hover {
              transform: scale(95%);
            }
          }

          &__header {
            height: 165px;
            background: linear-gradient(90deg, #38B066 0%, #48be75 100%);
            padding: 40px 28px 0;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            width: 100%;

            .title {
              color: white;
            }

            .title {
              @include Mixins.default-font-picker(28px, 700, white);
              margin-bottom: 12px;
            }

            .cost {
              @include Mixins.default-font-picker(18px, 700, #4A2390);
              background: #E8DDF7;
              border-radius: 14px;
              padding: 6px 20px;
              width: fit-content;
            }
          }

          &__list {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: fit-content;
            position: relative;
            padding: 0 28px 5px;

            .list__item {
              @include Mixins.default-font-picker(16px, 500, #54466A);
              line-height: 30px;
              padding-left: 20px;
              margin-bottom: 10px;

              &::before {
                display: block;
                content: "";
                width: 3px;
                height: 3px;
                background-color: #54466A;
                position: absolute;
                border-radius: 50%;

                left: 28px;
                transform: translateY(13px);
              }
            }
          }
        }

        .rates-item__middle {
          padding: 0;

          .rates-item__header {
            height: 165px;
            background: linear-gradient(90deg, #9C70FF 0%, #7C37FA 100%);
            padding: 40px 28px 0;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;

            .title {
              color: white;
            }
          }

          .rates-item__list {
            padding: 0 28px 40px;
          }

          .list__item {
            &::before {
              left: 28px;
            }
          }
        }
      }
    }
  }

  &__faq {
    .flex-container {
      padding-top: 100px;

      @media (max-width: 1024px) {
        padding: 40px 14px;
      }

      .faq-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;

        padding: 74px;

        background-color: #E8DDF7;
        border-radius: 50px;

        @media (max-width: 769px) {
          padding: 36px 32px;
        }

        .line {
          width: 75px;
          height: 2px;
          background-color: #7C37FA;
          border: 2px solid #7C37FA;
          border-radius: 3px;
          margin-bottom: 20px;
        }

        .title {
          @include Mixins.default-font-picker(36px, 700, #7C37FA);
          margin-bottom: 40px;
        }
      }
    }
  }

  &__contacts {
    height: 800px;
    width: 100%;

    @media (max-width: 1024px) {
      height: fit-content;
      padding-bottom: 40px;
    }

    .flex-container {
      height: 100%;
      align-items: center;

      @media (max-width: 1024px) {
        height: fit-content;
        flex-direction: column;
      }

      .contacts-info {
        display: flex;
        flex-direction: column;
        // flex-basis: 950px;
        // flex-shrink: 1;
        width: 90%;
        height: 575px;

        padding: 130px 100px;

        background: linear-gradient(110.51deg, #9C70FF 1.15%, #7C37FA 100%);
        border-radius: 50px;

        @media (max-width: 1024px) {
          padding: 44px 56px;
          height: fit-content;
        }

        @media (max-width: 768px) {
          padding: 44px 32px;
          width: 100%;
        }

        .contact {
          margin-bottom: 18px;

          &__title {
            @include Mixins.default-font-picker(16px, 600, white);
            margin-bottom: 10px;
          }

          &__info {
            @include Mixins.default-font-picker(14px, 400, white);
          }
        }
      }

      .contact-me {
        position: absolute;
        width: 574px;
        height: 430px;
        right: 0;

        padding: 50px 70px 0 70px;

        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 7px 20px rgba(91, 45, 149, 0.25);
        border-radius: 30px;

        @media (max-width: 1160px) {
          width: 574px;
        }

        @media (max-width: 1084px) {
          width: 437px;
          height: 430px;
          right: 0;
          padding: 50px 20px 0 20px;
        }

        @media (max-width: 1024px) {
          margin-top: -48px;
          // transform: scale(100%);
          position: relative;
          width: 100%;
          right: auto;
        }

        @media (max-width: 768px) {
          padding: 42px 32px 0 33px;
        }

        .title {
          @include Mixins.default-font-picker(24px, 700, #7C37FA);
          line-height: 30px;
          margin-bottom: 10px;
        }

        .main-text {
          @include Mixins.default-font-picker(14px, 500, #27016C);
          line-height: 26px;
          margin-bottom: 20px;
        }

        .text-input {
          width: 100%;
          height: 50px;
          outline: none;
          border-radius: 15px;
          background: #F2F0F9;

          padding: 15px 27px;

          @include Mixins.default-font-picker(16px, 600, black);
          border: none;
          margin-bottom: 10px;
        }

        .send-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          width: 280px;
          background: linear-gradient(90deg, #A882FF 0%, #6A1FFA 100%);
          border-radius: 15px;

          @include Mixins.default-font-picker(14px, 600, white);
          transition: 300ms;
          cursor: pointer;

          &:hover {
            transform: scale(95%);
          }

          @media (max-width: 425px) {
            width: 100%;
          }
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}