@mixin default-font-picker($size, $weight, $color) {
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

@mixin logo-params($size, $color, $image) {
  width: $size;
  height: $size;
  background-color: $color;
  background-image: url($image);
}